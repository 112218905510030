import axios from "axios";
import { affiliates } from "../../../common/enums/affiliates";
import { analyticsConstants } from "../../../common/constants/analytics";
import { GetApiUrl } from "../../general.service";
import { httpRequestMethods } from "../../../common/enums/httpRequestMethods";

let currentPathname = "";

export const initRelay42forKLM = (affiliate) => {
  if (!document.getElementById(`relay42-${affiliate}`)) {
    window.dataLayer = window.dataLayer || [];
    const script_node = document.createElement("script");
    script_node.setAttribute("id", `relay42-${affiliate}`);
    script_node.innerHTML = `(function(a, d, e, b, f, c, s) {
          a[b] =
            a[b] ||
            function() {
              a[b].q.push(arguments);
            };
          a[b].q = [];
          c = d.createElement(e);
          c.async = 1;
          c.src = '//tdn.r42tag.com/lib/' + f + '.js';
          s = d.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(c, s);
        })(window, document, 'script', '_st', '135-v1');
        _st('addTagProperties', {
          country: 'NL',
          language: 'EN',
          host: 'KL'
        });
        _st('loadTags');`;
    document.getElementsByTagName("head")[0].appendChild(script_node);
  }
};

const setRelay42InfoKLM = (relay42EventList) => {
  const existingRelay42TagsScriptNodes = document.getElementsByClassName(
    `relay42-tags-script-node`
  );
  Array.from(existingRelay42TagsScriptNodes).forEach((e) => e.remove());

  relay42EventList.forEach((element) => {
    let relayScript = "";
    relayScript += `_st("resetTags");`;

    if (
      element.pageStructure &&
      Array.isArray(element.pageStructure) &&
      element.pageStructure.length > 0
    ) {
      let setPageStructure = `_st("setPageStructure",`;

      element.pageStructure.forEach((structureElement, index) => {
        setPageStructure += `\"${structureElement}\"`;

        if (index < element.pageStructure.length - 1) {
          setPageStructure += `,`;
        } else {
          setPageStructure += `);`;
        }
      });

      relayScript += setPageStructure;
    }

    if (
      element.analyticsEvent &&
      element.analyticsEvent.eventData &&
      typeof element.analyticsEvent.eventData === "object"
    ) {
      if (window._st.tracking) {
        const sendEngagement = `_st.tracking.sendEngagement(\"${
          element.eventName
        }\", ${JSON.stringify(element.analyticsEvent.eventData)});`;
        relayScript += sendEngagement;
      }

      const addLocalTagProperties = `_st("addLocalTagProperties", ${JSON.stringify(
        element.analyticsEvent.eventData
      )});`;
      relayScript += addLocalTagProperties;
    }

    relayScript += '_st("loadTags");';
    const script_node = document.createElement("script");
    script_node.classList.add("relay42-tags-script-node");
    script_node.innerHTML = relayScript;
    document.getElementsByTagName("head")[0].appendChild(script_node);
  });
};

const getApiPath = (eventName) => {
  return analyticsConstants.apiPaths[eventName];
};

const waitForTrackingAvailable = async () => {
  const waitUntil = (condition, checkInterval = 500) => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!condition()) return;
        clearInterval(interval);
        resolve();
      }, checkInterval);
    });
  };

  await waitUntil(() => window._st.tracking);
  return;
};

const getRelay42Info = async (eventName, eventData) => {
  const requestUrl = GetApiUrl() + getApiPath(eventName);
  let isPostRequest = false;

  if (eventData instanceof Object) {
    isPostRequest = true;
  }

  const axiosConfig = {
    method: isPostRequest ? httpRequestMethods.POST : httpRequestMethods.GET,
    url: `${requestUrl}${isPostRequest ? "" : eventData}`,
    data: eventData,
  };

  await waitForTrackingAvailable();
    const response = await axios.request(axiosConfig);
    if (response && response.data && response.data.analyticsEventList) {
      setRelay42InfoKLM(response.data.analyticsEventList);
    }
};

export const eventTrigger = (event) => {
  if (
    event.type === analyticsConstants.eventType.pageView
    && currentPathname !== window.location.pathname
  ) {
    currentPathname = window.location.pathname;
    getRelay42Info(event.name, event.data);
  }
  else {
    getRelay42Info(event.name, event.data);
  }
};

export const Relay42Service = process.env.REACT_APP_AFFILIATE === affiliates.klm
  ? {
    initRelay42forKLM: initRelay42forKLM,
    eventTrigger: eventTrigger
  }
  : null;
