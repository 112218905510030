import axios from "axios";
import { GetApiUrl } from "../general.service";
import { LocalStorageService } from "../local-storage.service";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";

export const InitiatePayment = async (checkoutId) => {
  const response = await axios.post(
    `${GetApiUrl()}/Checkout/${checkoutId}/payment`,
    null
  );
  return response;
};

export const GetCosts = async (checkoutId, encryptedCardNumber) => {
  const response = await axios.put(
    `${GetApiUrl()}/Checkout/${checkoutId}/PaymentCalculateCost`,
    { EncryptedCreditCardNumber: encryptedCardNumber }
  );
  return response;
};

export const SelectPaymentMethod = async (checkoutId, paymentDetailsDTO) => {
  const response = await axios.put(
    `${GetApiUrl()}/Checkout/${checkoutId}/SelectPayment`,
    paymentDetailsDTO
  );
  return response;
};

export const MakePayment = async (checkoutId) => {
  let returnUrl = window.location.href;
  if (returnUrl.charAt(returnUrl.length - 1) === "/") {
    returnUrl = returnUrl.substring(0, returnUrl.length - 1);
  }
  returnUrl = returnUrl.substring(0, returnUrl.lastIndexOf("/"));
  returnUrl += "/payment";

  const response = await axios.post(
    `${GetApiUrl()}/PaymentConfirmation/${checkoutId}`,
    {
      returnUrl: process.env.REACT_APP_IS_DEV ? returnUrl : "",
    }
  );
  LocalStorageService.setLocalStorageItem(
    LocalStorageEnum.LAST_HOLIDAY_KEY,
    checkoutId
  );
  return response;
};

export const FinalisePayment = async (redirectResult, checkoutId) => {
  const response = await axios.put(
    `${GetApiUrl()}/PaymentConfirmation/${checkoutId}/redirectresult`,
    {
      redirectResult: redirectResult,
    }
  );
  return response;
};
