export const analyticsConstants = {
    platform: {
        gtag: "gtag",
        gtm: "gtm",
        gtmRelay42: "gtmRelay42",
        relay42: "relay42",
        unknown: "unknown event platform"
    },
    eventType: {
        customEvent: "customEvent",
        pageView: "pageView"
    },
    engagements: {
        dynamicPageview: "DynamicPageview",
        holidaySearch: "holidaySearch"
    },
    apiEvents: {
        result: "result",
        holiday: "holiday",
        holidayChangeSearch: "holidayChangeSearch",
        checkAvailability: "checkAvailability",
        changeFlight: "changeFlight",
        extras: "extras",
        checkout: "checkout",
        payment: "payment",
        confirmation: "confirmation"
    },
    apiPaths: {
        result: "/analytics/result/",
        holiday: "/analytics/holiday/",
        holidayChangeSearch: "/analytics/result/holidaychangesearch",
        checkAvailability: "/analytics/holiday/checkavailability/",
        changeFlight: "/analytics/holiday/changeFlight/", 
        extras: "/analytics/extras/",
        checkout: "/analytics/checkout/",
        payment: "/analytics/payment/",
        confirmation: "/analytics/confirmation/"
    },
    affiliates: {
        klm: "klm",
        transavia: "transavia",
        unknown: "unknown affiliate"
    }
};
