import { GetAffiliate } from "../../general.service";
import { affiliates } from "../../../common/enums/affiliates";
import { analyticsConstants } from "../../../common/constants/analytics";
import { LocalStorageService } from "../../local-storage.service";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";

const gtagEnum = {
    commands: {
        event: "event",
        config: "config",
        js: "js"
    },
    types: {
        click: "click",
        conversion: "conversion",
        pageView: "page_view",
        scroll: "scroll"
    },
    indexes: {
        commands: 0,
        types: 1,
        parameters: 2
    }
}

const init = () => {
    const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID;

    if (REACT_APP_GTM_ID) {
        const gaTrackingIds = REACT_APP_GTM_ID.split(",");
        initGtagScript(gaTrackingIds);
    }
}

const initGtagScript = (gaTrackingIds) => {
    if (!document.getElementById("gtagConfig")) {
        const scriptElement = document.createElement("script");
        scriptElement.setAttribute("id", "gtagConfig");

        scriptElement.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        `;

        gaTrackingIds.forEach(gaTrackingId => {
            scriptElement.innerHTML += setGtagConfig(gaTrackingId);
        });

        document.getElementsByTagName("head")[0].appendChild(scriptElement);
    }
}

const setGtagConfig = (gaTrackingId) => {
    if (GetAffiliate() === affiliates.klm) {
        return `gtag("config", \"${gaTrackingId}\", {'send_page_view': false});`;
    }
    else if (GetAffiliate() === affiliates.transavia) {
        return `gtag("config", \"${gaTrackingId}\");`;
    }
    else return '';
}

const setGtagPageViewEventKLM = (searchDto) => {
    const flight_destination_iata_code = (searchDto && searchDto.destinationLocationCode)
        ? searchDto.destinationLocationCode
        : LocalStorageService.getLocalStorageItem(LocalStorageEnum.DESTINATION_LOCATIONS);

    const flight_origin_iata_code = (searchDto && searchDto.departureLocationCode)
        ? searchDto.departureLocationCode
        : LocalStorageService.getLocalStorageItem(LocalStorageEnum.DEPARTURE_LOCATION);

    (function () {
        window.dataLayer = window.dataLayer || [];
        const existingDataLayerPush = window.dataLayer.push;
        window.dataLayer.push = function () {
            const states = [].slice.call(arguments, 0);
            states.forEach(function (state) {
                if (
                    state[gtagEnum.indexes.commands] === gtagEnum.commands.event
                    && state[gtagEnum.indexes.types] === gtagEnum.types.pageView
                ) {
                    state[gtagEnum.indexes.parameters].flight_destination_iata_code = flight_destination_iata_code.toLowerCase();
                    state[gtagEnum.indexes.parameters].flight_origin_iata_code = flight_origin_iata_code.toLowerCase();
                }
            });
            return existingDataLayerPush.apply(window.dataLayer, states);
        };
    })();
}

const eventTrigger = (event) => {
    if (
        event.type === analyticsConstants.eventType.pageView
        && GetAffiliate() === affiliates.klm
    ) {
        setGtagPageViewEventKLM(event);
    }
};

export const GtagService = {
    init: init,
    eventTrigger: eventTrigger
};