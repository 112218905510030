import "./hotel-information.component.scss";
import { useRef } from "react";
import { GenericButton } from "../generic-button/generic-button";
import checkMarkKLM from "../../assets/icons/check-S.svg";
import checkMarkTransavia from "../../assets/icons/transavia/Icons_Small_Check.svg";
import { useEffect, useState } from "react";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../common/constants/breakpoints";
import { numberOfAmenitiesToDisplay } from "../../common/enums/numberOfAmenties";
import { GetAmenityDescription } from "../../common/enums/amenities";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import { compose } from "redux";

export const HotelInformationComponent = ({
  hotelData,
  contentfulHolidayPage,
  contentfulButtons,
  detailedHolidayData,
}) => {
  const [
    isDetailedHolidayDataAvailable,
    setStateIsDetailedHolidayDataAvailable,
  ] = useState(false);
  const affiliate = GetAffiliate();
  const windowSize = useWindowSizeDetector();
  const [isMobile, setIsMobile] = useState(false);
  const descriptionRef = useRef(null);
  const [generalDescriptions, setGeneralDescriptions] = useState([]);
  const [storedAmenities, setStoredAmenities] = useState([]);
  const [checkInInstructions, setCheckInInstructions] = useState([]);
  const [amenitiesDescriptions, setAmenitiesDescriptions] = useState([]);
  const [knowBeforeYouGoDescriptions, setKnowBeforeYouGoDescriptions] =
    useState([]);
  const [mandatoryFeeDescriptions, setMandatoryFeeDescriptions] = useState([]);
  const [isContentTall, setIsContentTall] = useState(false);
  const [expandDescription, setExpandDescription] = useState(false);
  const [expandedDescriptionTop, setExpandedDescriptionTop] = useState(false);
  const [MaxHeightOfDescription, SetMaxHeightOfDescription] = useState(168);
  useEffect(() => {
    windowSize.width > breakpoints.SM ? setIsMobile(false) : setIsMobile(true);
  }, [windowSize.width]);

  useEffect(() => {
    if (!detailedHolidayData) {
      loadStateFromLocalStorage();
    }
  }, []);

  useEffect(() => {
    if (detailedHolidayData) {
      setStateIsDetailedHolidayDataAvailable(true);
      updateStateWithApiData(detailedHolidayData);
    }
  }, [detailedHolidayData]);

  useEffect(() => {
    const checkContentHeight = () => {
      if (descriptionRef.current) {
        setIsContentTall(
          descriptionRef.current.scrollHeight > MaxHeightOfDescription
        );
      }
    };

    let descriptions = null;

    const storedDescriptions = localStorage.getItem("generalDescription");
    if (storedDescriptions) {
      descriptions = JSON.parse(storedDescriptions);
    } else if (
      detailedHolidayData?.hotelInfo?.hotelDescriptions?.generalDescription
    ) {
      descriptions =
        detailedHolidayData.hotelInfo.hotelDescriptions.generalDescription;
    }
    const observer = new MutationObserver(checkContentHeight);
    if (descriptionRef.current) {
      observer.observe(descriptionRef.current, {
        childList: true,
        subtree: true,
        attributes: true,
      });
    }
    if (descriptions) {
      checkContentHeight();
    }

    return () => observer.disconnect();
  }, [detailedHolidayData]);

  const updateStateWithApiData = (data) => {
    const hotelDescriptions = data?.hotelInfo?.hotelDescriptions;

    setGeneralDescriptions(hotelDescriptions.generalDescription || []);
    setCheckInInstructions(hotelDescriptions.checkInInstruction || []);
    setAmenitiesDescriptions(hotelDescriptions.amenitiesDescription || []);
    setKnowBeforeYouGoDescriptions(
      hotelDescriptions.knowBeforeYouGoDescription || []
    );
    setMandatoryFeeDescriptions(
      hotelDescriptions.mandatoryFeeDescription || []
    );

    if (hotelData?.data?.hotelInfo?.amenities) {
      setStoredAmenities(hotelData?.data?.hotelInfo?.amenities || []);
      localStorage.setItem(
        "hotelAmenities",
        JSON.stringify(hotelData?.data?.hotelInfo?.amenities || [])
      );
    }

    localStorage.setItem(
      LocalStorageEnum.GENERAL_DESCRIPTION,
      JSON.stringify(hotelDescriptions.generalDescription || [])
    );
    localStorage.setItem(
      LocalStorageEnum.HOTEL_AMENITIES,
      JSON.stringify(hotelData?.data?.hotelInfo?.amenities || [])
    );
    localStorage.setItem(
      LocalStorageEnum.CHECK_IN_INSTRUCTION,
      JSON.stringify(hotelDescriptions.checkInInstruction || [])
    );
    localStorage.setItem(
      LocalStorageEnum.AMENITIES_DESCRIPTION,
      JSON.stringify(hotelDescriptions.amenitiesDescription || [])
    );
    localStorage.setItem(
      LocalStorageEnum.KNOW_BEFORE_YOU_GO_DESCRIPTION,
      JSON.stringify(hotelDescriptions.knowBeforeYouGoDescription || [])
    );
    localStorage.setItem(
      LocalStorageEnum.MANDATORY_FEE_DESCRIPTION,
      JSON.stringify(hotelDescriptions.mandatoryFeeDescription || [])
    );
  };

  const loadStateFromLocalStorage = () => {
    setGeneralDescriptions(
      JSON.parse(
        localStorage.getItem(LocalStorageEnum.GENERAL_DESCRIPTION) || "[]"
      )
    );
    setStoredAmenities(
      JSON.parse(localStorage.getItem(LocalStorageEnum.HOTEL_AMENITIES) || "[]")
    );
    setCheckInInstructions(
      JSON.parse(
        localStorage.getItem(LocalStorageEnum.CHECK_IN_INSTRUCTION) || "[]"
      )
    );
    setAmenitiesDescriptions(
      JSON.parse(
        localStorage.getItem(LocalStorageEnum.AMENITIES_DESCRIPTION) || "[]"
      )
    );
    setKnowBeforeYouGoDescriptions(
      JSON.parse(
        localStorage.getItem(LocalStorageEnum.KNOW_BEFORE_YOU_GO_DESCRIPTION) ||
          "[]"
      )
    );
    setMandatoryFeeDescriptions(
      JSON.parse(
        localStorage.getItem(LocalStorageEnum.MANDATORY_FEE_DESCRIPTION) || "[]"
      )
    );
  };

  useEffect(() => {
    if (detailedHolidayData) {
      setStateIsDetailedHolidayDataAvailable(true);
    }
  }, [detailedHolidayData]);

  const handleOnExpandButtonClick = () => {
    if (isDetailedHolidayDataAvailable) {
      setExpandDescription(!expandDescription);
    }
  };

  const defineNumberOfFacilities = () => {
    if (expandDescription) {
      return hotelData?.data?.hotelInfo?.amenities?.length || 0;
    }

    if (isMobile) {
      return numberOfAmenitiesToDisplay.mobile;
    } else {
      return numberOfAmenitiesToDisplay.desktop;
    }
  };

  const getDescriptionText = (listItem) => {
    let returnMarkup = listItem?.text;
    return (
      <div className="hotel-description-markup-text">
        <p>{returnMarkup}</p>
        {listItem?.listItems && (
          <ul>
            {listItem.listItems.map((x, index) => {
              return <li key={index}>{x}</li>;
            })}
          </ul>
        )}
      </div>
    );
  };

  const getAmenitiesDescriptionText = (listItem) => {
    let returnMarkup = listItem?.text;
    let returnList = listItem?.listItems;

    if (returnList) {
      const midIndex = Math.ceil(returnList.length / 2);
      const firstHalf = returnList.slice(0, midIndex);
      const secondHalf = returnList.slice(midIndex);

      return (
        <div className="hotel-amenities-markup-text">
          <ul>
            {firstHalf.map((x, index) => (
              <li key={index}>{x}</li>
            ))}
          </ul>
          <ul>
            {secondHalf.map((x, index) => (
              <li key={midIndex + index}>{x}</li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="hotel-description-markup-text">
          <p>{returnMarkup}</p>
        </div>
      );
    }
  };

  const isThereDetailToShow = () => {
    const dataAvailableInState = [
      checkInInstructions,
      amenitiesDescriptions,
      knowBeforeYouGoDescriptions,
      mandatoryFeeDescriptions,
    ].some((array) => array.length > 0);

    const dataAvailableInLocalStorage = [
      "hotelDescription_checkInInstruction",
      "hotelDescription_amenitiesDescription",
      "hotelDescription_knowBeforeYouGoDescription",
      "hotelDescription_mandatoryFeeDescription",
    ].some(
      (key) =>
        localStorage.getItem(key) !== null &&
        JSON.parse(localStorage.getItem(key)).length > 0
    );

    return dataAvailableInState || dataAvailableInLocalStorage;
  };

  return (
    <div className="hotel-information-component">
      <div className="hotel-information-overview">
        <div className="hotel-information-subtitle">
          {contentfulHolidayPage &&
            contentfulHolidayPage[0]?.fields?.hotelInformationOverview}
        </div>
        <div
          ref={descriptionRef}
          className={`hotel-information-overview-description ${
            isContentTall ? "is-content-tall" : ""
          } ${expandedDescriptionTop ? "show-full-text" : ""}`}
        >
          {generalDescriptions.map((description, index) => (
            <div key={index}>{getDescriptionText(description)}</div>
          ))}
        </div>
        {isContentTall && (
          <div
            onClick={() => setExpandedDescriptionTop(!expandedDescriptionTop)}
            className="read-more-less-button"
          >
            {expandedDescriptionTop
              ? contentfulHolidayPage &&
                contentfulHolidayPage[0]?.fields?.readLess
              : contentfulHolidayPage &&
                contentfulHolidayPage[0]?.fields?.readMore}
          </div>
        )}
      </div>

      <div className="hotel-information-facilities">
        <div className="hotel-information-subtitle">
          {contentfulHolidayPage &&
            contentfulHolidayPage[0]?.fields?.hotelInformationFacilities}
        </div>
        <ul className="hotel-information-facilities-list">
          {storedAmenities.map((amenity, index) => (
            <li
              className="hotel-information-facilities-list-element"
              key={index}
            >
              <img
                src={
                  affiliate === affiliates.klm
                    ? checkMarkKLM
                    : checkMarkTransavia
                }
                alt="check_mark"
              />
              <span>{GetAmenityDescription(amenity)}</span>
            </li>
          ))}
        </ul>
      </div>

      {expandDescription && isThereDetailToShow() && (
        <div className="hotel-information-conditions">
          <div className="hotel-information-subtitle">
            {detailedHolidayData?.hotelInfo?.hotelDescriptions
              ?.checkInInstruction.length > 0 &&
              contentfulHolidayPage &&
              contentfulHolidayPage[0]?.fields?.hotelCheckInInstructions}
          </div>
          <div className="hotel-information-conditions-description">
            {checkInInstructions.map((x, index) => (
              <div key={index}>{getDescriptionText(x)}</div>
            ))}
          </div>
        </div>
      )}

      {expandDescription && isThereDetailToShow() && (
        <div className="hotel-information-conditions">
          <div className="hotel-information-subtitle">
            {detailedHolidayData?.hotelInfo?.hotelDescriptions
              ?.amenitiesDescription.length > 0 &&
              contentfulHolidayPage &&
              contentfulHolidayPage[0]?.fields?.hotelAmenitiesHeading}
          </div>
          <div className="hotel-information-conditions-description">
            {amenitiesDescriptions.map((x, index) => (
              <div key={index}>{getAmenitiesDescriptionText(x)}</div>
            ))}
          </div>
        </div>
      )}

      {expandDescription && isThereDetailToShow() && (
        <div className="hotel-information-conditions">
          <div className="hotel-information-subtitle">
            {detailedHolidayData?.hotelInfo?.hotelDescriptions
              ?.knowBeforeYouGoDescription.length > 0 &&
              contentfulHolidayPage &&
              contentfulHolidayPage[0]?.fields?.hotelKnowBeforeYouGoHeading}
          </div>
          <div className="hotel-information-conditions-description">
            {knowBeforeYouGoDescriptions.map((x, index) => (
              <div key={index}>{getDescriptionText(x)}</div>
            ))}
          </div>
        </div>
      )}

      {expandDescription && isThereDetailToShow() && (
        <div className="hotel-information-conditions">
          <div className="hotel-information-subtitle">
            {detailedHolidayData?.hotelInfo?.hotelDescriptions
              ?.mandatoryFeeDescription.length > 0 &&
              contentfulHolidayPage &&
              contentfulHolidayPage[0]?.fields?.hotelMandatoryFeeHeading}
          </div>
          <div className="hotel-information-conditions-description">
            {mandatoryFeeDescriptions.map((x, index) => (
              <div key={index}>{getDescriptionText(x)}</div>
            ))}
          </div>
        </div>
      )}

      <GenericButton
        className={
          isDetailedHolidayDataAvailable
            ? "hotel-information-button"
            : "hotel-information-button button-disabled"
        }
      >
        {expandDescription && (
          <div className="button-container" onClick={handleOnExpandButtonClick}>
            <div>
              {contentfulButtons &&
                contentfulButtons[0]?.fields?.showLessInformation}
            </div>
            <div className="dropdown-caret rotate"></div>
          </div>
        )}
        {!expandDescription && (
          <div className="button-container" onClick={handleOnExpandButtonClick}>
            {contentfulButtons &&
              contentfulButtons[0]?.fields?.showMoreInformation}
            <div className="dropdown-caret"></div>
          </div>
        )}
      </GenericButton>
    </div>
  );
};
