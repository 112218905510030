import { affiliates } from "../../common/enums/affiliates";
import { analyticsConstants } from "../../common/constants/analytics";
import { GtagService } from "./gtag/gtag.service";
import { GtmService } from "./gtm/gtm.service";
import { Relay42Service } from "./relay42/relay42.service";

const init = () => {
    GtagService.init();

    if (process.env.REACT_APP_AFFILIATE === affiliates.klm) {
        Relay42Service.initRelay42forKLM(affiliates.klm);
    }
    else if (process.env.REACT_APP_AFFILIATE === affiliates.transavia) {
        GtmService.initGoogleTagManagerForTransavia(affiliates.transavia);
    }
};

const handleEvents = (events) => {
    for (const event of events) {
        switch (event.platform) {
            case analyticsConstants.platform.gtag:
                GtagService.eventTrigger(event);
                break;
            case analyticsConstants.platform.gtmRelay42:
                handleEventForGtmAndRelay42(event);
                break;
            default:
                console.error(analyticsConstants.platform.unknown);
        }
    }
}

const handleEventForGtmAndRelay42 = (event) => {
    switch (process.env.REACT_APP_AFFILIATE) {
        case (affiliates.klm):
            Relay42Service?.eventTrigger(event);
            break;
        case (affiliates.transavia):
            GtmService?.eventTrigger(event);
            break;
        default:
            console.error(analyticsConstants.affiliates.unknown);
    }
}

export const AnalyticsService = {
    analyticsConstants: analyticsConstants,
    init: init,
    handleEvents: handleEvents
}