import axios from "axios";
import { analyticsConstants } from "../../../common/constants/analytics";
import { GetAffiliate } from "../../general.service";
import { affiliates } from "../../../common/enums/affiliates";
import { GetApiUrl } from "../../general.service";
import { httpRequestMethods } from "../../../common/enums/httpRequestMethods";

let currentPathname = "";

const initGoogleTagManagerForTransavia = () => {
  if (!document.getElementById(`${GetAffiliate().toLowerCase()}-gtm`)) {
    const analyticsIsDev = process.env.REACT_APP_ANALYTICS_IS_DEV === "true";
    const googleTagManagerSrc = analyticsIsDev
      ? "https://collector.tst.transaviaws.net/tags.js"
      : "https://collector.transavia.com/tags.js";  

    window._waq = window._waq || [];
    const scriptNode = document.createElement("script");
    scriptNode.setAttribute("id", `${GetAffiliate().toLowerCase()}-gtm`);
    scriptNode.innerHTML = `(function (w, d, s, l) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s);
        j.async = true;
        j.src = "${googleTagManagerSrc}";
        f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer");`;

    document.getElementsByTagName("head")[0].appendChild(scriptNode);
  }
};

const setAnalyticsInfoTransavia = (analyticsEventList) => {
  analyticsEventList.forEach(event => {
    window._waq.push({
      eventName: event.eventName,
      eventData: event.analyticsEvent.eventData
    });
  });
};

const getApiPath = (eventName) => {
  return analyticsConstants.apiPaths[eventName];
};

const getAnalyticsInfo = async (eventName, eventData) => {
    const requestUrl = GetApiUrl() + getApiPath(eventName);
    let isPostRequest = false;
  
    if (eventData instanceof Object) {
      isPostRequest = true;
    }
  
    const axiosConfig = {
      method: isPostRequest ? httpRequestMethods.POST : httpRequestMethods.GET,
      url: `${requestUrl}${isPostRequest ? "" : eventData}`,
      data: eventData,
    };

    const response = await axios.request(axiosConfig);
    if (response && response.data && response.data.analyticsEventList) {
      setAnalyticsInfoTransavia(response.data.analyticsEventList);
    }
};

export const eventTrigger = (event) => {
  if (
    event.type === analyticsConstants.eventType.pageView
    && currentPathname !== window.location.pathname
  ) {
    currentPathname = window.location.pathname;
    getAnalyticsInfo(event.name, event.data);
  }
  else {
    getAnalyticsInfo(event.name, event.data);
  }
};

export const GtmService = process.env.REACT_APP_AFFILIATE === affiliates.transavia
    ? {
        initGoogleTagManagerForTransavia: initGoogleTagManagerForTransavia,
        eventTrigger: eventTrigger
    }
    : null;