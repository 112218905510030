import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { breakpoints } from "../../common/constants/breakpoints";
import { contentType } from "../../common/constants/contentType";
import { pagination } from "../../common/constants/pagination";
import { affiliates } from "../../common/enums/affiliates";
import { HttpStatusEnum } from "../../common/enums/httpStatusEnum";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import { EmptyStateInfoComponent } from "../../components/empty-state-info/empty-state-info.component";
import { FiltersOverviewComponent } from "../../components/filters/filters-overview/filters-overview.component";
import { FiltersComponent } from "../../components/filters/filters.component";
import { SelectedFilterComponent } from "../../components/filters/selected-filters/selected-filters.component";
import { HolidayCardComponent } from "../../components/holiday-card/holiday-card.component";
import { HolidayListSortComponent } from "../../components/holiday-list-sort/holiday-list-sort.component";
import HotelListMapComponent from "../../components/hotel-list-map/hotel-list-map.component";
import { MapComponent } from "../../components/map/map.component";
import { PaginationComponent } from "../../components/pagination/pagination-component";
import { SearchBarComponent } from "../../components/search-bar/search-bar.component";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import {
  getContentfulByContentType,
  parseContentfulDynamicContent,
} from "../../services/contentful.service";
import {
  GetApiUrl,
  usePreviousState,
  compareStates,
  GetDateOnYearFromNow,
} from "../../services/general.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { SampleSearchRequestBody } from "../../services/mockDataService";
import ContentfulContext from "../../store/contentful/contentful-context";
import { useCookies } from "react-cookie";
import { Cookies } from "../../common/constants/cookies";
import { SessionStorageService } from "../../services/session-storage.service";
import { SearchDtoParamsEnum } from "../../common/enums/searchDtoParamsEnum";
import { AnalyticsService } from "../../services/analytics/analytics.service";

export const ResultsPageComponent = ({
  queryParameters,
  culture,
  isWaitingForNetwork,
  setIsWaitingForNetwork,
  isMobile,
  setIsMobile,
}) => {
  const listMapRef = useRef(null);
  const prevSearchDto = useRef();
  const [holidaysData, setHolidaysData] = useState([]);
  const [selectedHotelFromCard, setSelectedHotelFromCard] = useState([]);
  const [searchInfo, setSearchInfo] = useState(null);
  const [isMapExtended, setIsMapExtended] = useState(false);
  const contentfulEntries = useContext(ContentfulContext);
  const [minLowerCardWidth, setMinLowerCardWidth] = useState(0);
  const [lastWindowWidth, setLastWindowWidth] = useState(window.innerWidth);
  const [holidayFullData, setHolidayFullData] = useState(null);
  const [contentfulResultsPageResponse, setContentfulResultsPageResponse] =
    useState([{}]);
  const [paginationNumberOfResults, setPaginationNumberOfResults] =
    useState("");
  const [holidayListKey, setStateHolidayListKey] = useState(null);
  const [pageAndSortInfo, setStatePageAndSortInfo] = useState(null);
  const [isTablet, setIsTablet] = useState(false);
  const [contentfulButtons, setStateContentfulButtons] = useState(false);
  const [contentfulFilters, setContentfulFilters] = useState(false);
  const [previousNumberOfFiltersApplied, setPreviousNumberOfFiltersApplied] =
    useState(0);
  const [numberOfFiltersApplied, setNumberOfFiltersApplied] = useState(0);
  const [fullHolidaysData, setFullHolidaysData] = useState([]);
  const [mapClickedFromHolidayCard, setMapClickedFromHolidayCard] =
    useState(false);
  const [previousFilters, setPreviousFilters] = useState(null);
  const [filters, setFilters] = useState(null);
  const [defaultSearchUsed, setDefaultSearchUsed] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [cookies, setCookie] = useCookies();

  const windowSize = useWindowSizeDetector();

  useEffect(() => {
    prevSearchDto.current = SampleSearchRequestBody;
  }, []);

  useEffect(() => {
    windowSize.width > breakpoints.SM && windowSize.width < breakpoints.XL
      ? setIsTablet(true)
      : setIsTablet(false);
  }, [windowSize.width]);

  useEffect(() => {
    const isFilterChanged = hasFiltersChanged(
      holidayFullData?.data?.filterInfo
    );
    if (holidayFullData?.data?.holidayListKey && isFilterChanged) {
      if (isMapExtended) {
        getAllHolidaysData();
      }
    }
    setPreviousFilters(holidayFullData?.data?.filterInfo);
    setPreviousNumberOfFiltersApplied(
      holidayFullData?.data?.filterInfo?.numberOfFiltersApplied
    );
  }, [numberOfFiltersApplied, isMapExtended, filters]);

  const handleOnMapClick = () => {
    setIsMapExtended(!isMapExtended);
    if (!isMapExtended && !fullHolidaysData.data) {
      getAllHolidaysData();
    }
    setSelectedHotelFromCard(null);
  };

  const handleWithinHolidaysCardMapClick = (hotelDetails) => {
    setMapClickedFromHolidayCard(!mapClickedFromHolidayCard);
    if (!fullHolidaysData.data) {
      getAllHolidaysData();
    }
    setIsMapExtended((mapExtendedState) => (mapExtendedState = true));
    setSelectedHotelFromCard(hotelDetails);

    listMapRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const getResultsPageContent = () => {
    const contentfulResultsPageResponse = getContentfulByContentType(
      contentfulEntries,
      contentType.RESULTS_PAGE
    );
    setContentfulResultsPageResponse(contentfulResultsPageResponse);
  };

  const contentfulButtonsResponse = () => {
    const contentfulButtons = getContentfulByContentType(
      contentfulEntries,
      contentType.BUTTONS
    );
    setStateContentfulButtons(contentfulButtons);
  };

  const contentfulFiltersResponse = () => {
    const contentfulFilters = getContentfulByContentType(
      contentfulEntries,
      contentType.FILTER
    );
    setContentfulFilters(contentfulFilters);
  };

  useEffect(() => {
    if (contentfulResultsPageResponse?.length === 1) {
      const paginationHeaderNumberOfResults = parseContentfulDynamicContent(
        contentfulResultsPageResponse[0]?.fields?.resultsNumberHeader,
        holidaysData?.length,
        holidayFullData?.data?.pageAndSortInfo?.numberOfHolidaysFound
      );

      setPaginationNumberOfResults(paginationHeaderNumberOfResults);
    }
    setDefaultSearchUsed(
      holidayFullData?.data?.searchInfo?.searchValidationInfo?.defaultSearchUsed
    );
    setFilters(holidayFullData?.data?.filterInfo);
    getResultsPageContent();
    contentfulButtonsResponse();
    contentfulFiltersResponse();
  }, [contentfulEntries, holidaysData]);

  useEffect(() => {
    const getHolidaysData = async () => {
      const parsedParameters = remapSearchParameters();
      if (parsedParameters && Object.keys(parsedParameters).length > 0) {
        await performSearch(parsedParameters);
      } else {
        await performSearch(SampleSearchRequestBody);
      }
    };

    if (culture && !isWaitingForNetwork) {
      getHolidaysData();
    }
  }, [queryParameters, culture]);

  useEffect(() => {
    if (holidayFullData?.status == HttpStatusEnum.SUCCESS) {
      setIsLoading(false);
      setInitialLoading(false);
    }
  }, [holidayFullData]);

  const processApiResponse = (apiResponse) => {
    setSearchInfo(apiResponse.data.searchInfo);
    setHolidaysData(apiResponse.data.holidays);
    setHolidayFullData(apiResponse);
    setStateHolidayListKey(apiResponse.data.holidayListKey);
    setStatePageAndSortInfo(apiResponse.data.pageAndSortInfo);
    setNumberOfFiltersApplied(
      apiResponse.data.filterInfo.numberOfFiltersApplied
    );
    performScrollToTop();
  };

  const performSearch = async (searchDto) => {
    setHolidaysData([]);
    setIsWaitingForNetwork(true);
    setIsLoading(true);
    const queryChanges = compareStates(searchDto, prevSearchDto.current);
    const newHolidayResultsData = await axios.post(
      `${GetApiUrl()}/HolidaySearch/search?clientKey=${process.env?.REACT_APP_AFFILIATE ?? affiliates.klm
      }`,
      {
        filters: filters?.requestedFilters,
        deepLink: window.location.href,
        ...searchDto,
        marketingInfo: {
          utmSource: queryParameters?.utm_source ?? "",
          utmMedium: queryParameters?.utm_medium ?? "",
          utmCampaign: queryParameters?.utm_campaign ?? "",
        },
        authInfo: {
          authKey: cookies?.authKey ?? "",
          sessionId: SessionStorageService.getSessionId() ?? "",
        },
        haveDatesChanged:
          queryChanges.includes(SearchDtoParamsEnum.departureDate) ||
          queryChanges.includes(SearchDtoParamsEnum.returnDate),
      }
    );
    if (!cookies.authKey) {
      setCookie(
        Cookies.AuthKey,
        newHolidayResultsData?.data?.authInfo?.authKey,
        {
          expires: GetDateOnYearFromNow(),
        }
      );
    }
    if (!SessionStorageService.getSessionId()) {
      SessionStorageService.setSessionId(
        newHolidayResultsData?.data?.authInfo?.sessionId
      );
    }
    setIsWaitingForNetwork(false);
    processApiResponse(newHolidayResultsData);

    if (queryChanges?.length && holidayListKey) {
      AnalyticsService.handleEvents(
        [
          {
            platform: AnalyticsService.analyticsConstants.platform.gtmRelay42,
            type: AnalyticsService.analyticsConstants.eventType.customEvent,
            name: AnalyticsService.analyticsConstants.apiEvents.holidayChangeSearch,
            data: {
              holidayListKey: holidayListKey,
              queryChanges: queryChanges,
            }
          }
        ]
      );
      prevSearchDto.current = searchDto;
    }

    AnalyticsService.handleEvents(
      [
        {
          platform: AnalyticsService.analyticsConstants.platform.gtmRelay42,
          type: AnalyticsService.analyticsConstants.eventType.pageView,
          name: AnalyticsService.analyticsConstants.apiEvents.result,
          data: newHolidayResultsData.data.holidayListKey
        },
        {
          platform: AnalyticsService.analyticsConstants.platform.gtag,
          type: AnalyticsService.analyticsConstants.eventType.pageView,
          data: searchDto
        }
      ]
    );
  };

  const performDestinationLocationUpdate = async (searchDto) => {
    const destinationLocationUpdateRequestUrl = `${GetApiUrl()}/HolidaySearch/update?clientKey=${
      process.env?.REACT_APP_AFFILIATE ?? affiliates.klm
    }`;

    const destinationLocationUpdateRequestBody = {
      holidayListKey: holidayFullData.data.holidayListKey,
      destinationLocationCode: searchDto.destinationLocationCode,
      filters: searchDto.filters,
    };

    setIsLoading(true);
    const updatedDestinationLocation = await axios.put(
      destinationLocationUpdateRequestUrl,
      destinationLocationUpdateRequestBody
    );

    processApiResponse(updatedDestinationLocation);
  };

  const remapSearchParameters = () => {
    const searchDto = {
      culture: culture,
      destinationLocationCode: queryParameters.destinationlocation,
      departureLocationCode: queryParameters.departurelocation,
      departureDate: queryParameters.departuredate,
      destinationAirportCode: queryParameters.destinationairportcode,
      returnDate: queryParameters.returndate,
      departureDateFlexibility: parseInt(queryParameters.departuredateflex),
      searchType: queryParameters.searchtype,
      roomAllocationCode: queryParameters.roomallocation,
    };
    LocalStorageService.setLocalStorageItem(
      LocalStorageEnum.DEPARTURE_LOCATION,
      queryParameters.departurelocation
    );
    LocalStorageService.setLocalStorageItem(
      LocalStorageEnum.DESTINATION_LOCATIONS,
      queryParameters.destinationlocation
    );
    return searchDto;
  };

  const paginateHolidayResultsData = async (currentPage) => {
    try {
      const destination = LocalStorageService.getLocalStorageItem(
        "destinationLocations"
      );
      setIsLoading(true);
      const paginatedHolidayData = await axios.put(
        `${GetApiUrl()}/HolidaySearch/update?clientKey=${
          process.env?.REACT_APP_AFFILIATE ?? affiliates.klm
        }`,
        {
          destinationLocationCode: destination,
          holidayListKey: holidayFullData.data.holidayListKey,
          pageAndSorting: {
            pageNumber: currentPage,
          },
          filters: holidayFullData?.data?.filterInfo?.requestedFilters,
        }
      );
      processApiResponse(paginatedHolidayData);
    } catch (error) {
      console.error(error);
    }
  };

  const updatePaginationNumber = async (currentPage) => {
    if (holidayFullData) {
      await paginateHolidayResultsData(currentPage);
    }
  };

  const performSort = async (sortRequestBody) => {
    const sortRequestUrl = `${GetApiUrl()}/HolidaySearch/update?clientKey=${
      process.env?.REACT_APP_AFFILIATE ?? affiliates.klm
    }`;
    const destination = LocalStorageService.getLocalStorageItem(
      "destinationLocations"
    );

    const sortRequestBodyWithFilters = {
      ...sortRequestBody,
      destinationLocationCode: searchInfo?.destinationLocationCode,
      filters: holidayFullData?.data?.filterInfo?.requestedFilters,
      destinationLocationCode: destination,
    };

    setIsLoading(true);
    const sortedHolidayData = await axios.put(
      sortRequestUrl,
      sortRequestBodyWithFilters
    );
    processApiResponse(sortedHolidayData);
  };

  const performFilter = async (filterRequestBody) => {
    const filterRequestUrl = `${GetApiUrl()}/HolidaySearch/update?clientKey=${
      process.env?.REACT_APP_AFFILIATE ?? affiliates.klm
    }`;
    const sortOption = holidayFullData?.data?.pageAndSortInfo?.sortOptions.find(
      (option) => option.selected === true
    );
    setIsLoading(true);
    const filteredHolidayData = await axios.put(filterRequestUrl, {
      ...filterRequestBody,
      destinationLocationCode: searchInfo?.destinationLocationCode,
      pageAndSorting: {
        currentPage: pagination.firstPage,
        sortType: sortOption.sortType,
      },
    });

    processApiResponse(filteredHolidayData);
  };

  const getAllHolidaysData = async () => {
    setIsWaitingForNetwork(true);
    const newFullHolidayResultsData = await axios.post(
      `${GetApiUrl()}/HolidaySearch/maplist?clientKey=${
        process.env?.REACT_APP_AFFILIATE ?? affiliates.klm
      }`,
      {
        holidayListKey: holidayFullData?.data?.holidayListKey,
        filters: holidayFullData?.data?.filterInfo?.requestedFilters,
      }
    );
    setFullHolidaysData(newFullHolidayResultsData);
    setIsWaitingForNetwork(false);
  };

  const performScrollToTop = () => {
    const element = document.getElementById("header-component-container-id");
    if (element) {
      element.scrollIntoView();
    }
  };

  const hasFiltersChanged = (filters) => {
    let isFilterChanged = false;

    if (filters && previousFilters) {
      const filtersCopy = structuredClone(filters);
      const previousFiltersCopy = structuredClone(previousFilters);

      if (
        previousFiltersCopy?.numberOfFiltersApplied !==
        filtersCopy?.numberOfFiltersApplied
      ) {
        isFilterChanged = true;
      }

      if (filtersCopy.requestedFilters) {
        Object.entries(filtersCopy.requestedFilters).forEach(([key, value]) => {
          if (
            value &&
            previousFiltersCopy.requestedFilters &&
            previousFiltersCopy?.requestedFilters[key] !== value
          ) {
            isFilterChanged = true;
          }
        });
      }

      if (filtersCopy?.destinationFilterChips) {
        Object.entries(filtersCopy?.destinationFilterChips).forEach(
          ([key, value]) => {
            if (
              previousFiltersCopy &&
              previousFiltersCopy?.destinationFilterChips[key] !== value
            ) {
              isFilterChanged = true;
            }
          }
        );
      }
    }
    return isFilterChanged;
  };

  return (
    <div id="results-page-container" className="results-page-container">
      <SearchBarComponent
        searchInfo={searchInfo}
        performSearch={performSearch}
      />

      <div className="results-page-content">
        <div className="results-page-right-content">
          <div className="header">
            <div className="results-page-content">
              {!isTablet && !isMobile ? (
                <div className="results-page-left-content">
                  <div
                    className="map-component-placeholder"
                    onClick={handleOnMapClick}
                  >
                    <MapComponent contentfulButtons={contentfulButtons} />
                  </div>
                  <FiltersComponent
                    contentfulButtons={contentfulButtons}
                    contentfulResultsPageResponse={
                      contentfulResultsPageResponse
                    }
                    contentfulFilters={contentfulFilters}
                    isMapExtended={handleOnMapClick}
                    holidayFullData={holidayFullData}
                    performSearch={performDestinationLocationUpdate}
                    performFilter={performFilter}
                    defaultSearchUsed={defaultSearchUsed}
                  />
                </div>
              ) : (
                <FiltersOverviewComponent
                  filtersLength={numberOfFiltersApplied}
                  isMapExtended={handleOnMapClick}
                  contentfulButtons={contentfulButtons}
                  contentfulResultsPageResponse={contentfulResultsPageResponse}
                  contentfulFilters={contentfulFilters}
                  holidayFullData={holidayFullData}
                  performSearch={performDestinationLocationUpdate}
                  performFilter={performFilter}
                  defaultSearchUsed={defaultSearchUsed}
                >
                  <SelectedFilterComponent
                    holidayFullData={holidayFullData}
                    contentfulButtons={contentfulButtons}
                    contentfulFilters={contentfulFilters}
                    contentfulResultsPageResponse={
                      contentfulResultsPageResponse
                    }
                    performSearch={performDestinationLocationUpdate}
                    performFilter={performFilter}
                  />
                </FiltersOverviewComponent>
              )}
              <div className="results-page-right-content" ref={listMapRef}>
                <div
                  className={`${
                    isMapExtended
                      ? "google-map-card-expanded"
                      : "google-map-card-wrapped"
                  }`}
                >
                  {isMapExtended && (
                    <HotelListMapComponent
                      fullHolidaysData={fullHolidaysData}
                      isMapExtended={handleOnMapClick}
                      contentfulButtons={contentfulButtons}
                      closeMapOnMobile={handleOnMapClick}
                      singleHotelData={selectedHotelFromCard}
                      mapClickedFromHolidayCard={mapClickedFromHolidayCard}
                    />
                  )}
                </div>
                <div className="results-header">
                  {!isLoading && (
                    <div className="pagination-number-results">
                      {paginationNumberOfResults && paginationNumberOfResults}
                    </div>
                  )}
                  {isLoading && (
                    <div className="pagination-number-results-loading">
                      <CircularProgress
                        className="pagination-number-loading-spinner"
                        size={6}
                      />
                      {contentfulResultsPageResponse &&
                        contentfulResultsPageResponse[0].fields
                          ?.pageIsLoadingText}
                    </div>
                  )}
                  {!isLoading && (
                    <HolidayListSortComponent
                      holidayListKey={holidayListKey}
                      pageAndSortInfo={pageAndSortInfo}
                      performSort={performSort}
                    />
                  )}
                </div>
                <EmptyStateInfoComponent
                  defaultSearchUsed={defaultSearchUsed}
                  contentfulEmptyStateInfo={
                    contentfulResultsPageResponse &&
                    contentfulResultsPageResponse[0].fields?.emptyStateInfo
                  }
                />

                {holidaysData.map((holiday, i) => {
                  return (
                    <HolidayCardComponent
                      setMinLowerCardWidth={setMinLowerCardWidth}
                      onMapClick={handleWithinHolidaysCardMapClick}
                      minLowerCardWidth={minLowerCardWidth}
                      setLastWindowWidth={setLastWindowWidth}
                      lastWindowWidth={lastWindowWidth}
                      holidayDetails={holiday}
                      key={i}
                      contentfulEntries={contentfulEntries}
                      isMobile={isMobile}
                      setIsMobile={setIsMobile}
                      contentfulButtons={contentfulButtons}
                    />
                  );
                })}
                {!initialLoading && holidaysData?.length > 0 && (
                  <PaginationComponent
                    holidayFullData={holidayFullData}
                    updatePaginationNumber={updatePaginationNumber}
                    pageAndSortInfo={pageAndSortInfo}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
